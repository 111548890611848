"use strict";

// burger

const burger = document.querySelector(".burger");
const menu = document.querySelector(".link");
var overlay = document.querySelector('.overlay');

const pagecontent = document.querySelector('.formulaireContact');
const contactbutton = document.querySelectorAll(".contactbutton");
const croix = document.querySelector(".croix");


burger.addEventListener('click', function () {
  if (burger.classList.contains("burger--open")) {
    burger.classList.toggle("burger--close");
  } else {
    burger.classList.add("burger--open");
  }

  overlay.classList.toggle('open');
});

menu.addEventListener('click', function () {
  overlay.classList.toggle('open');
  burger.classList.remove("burger--open");
});




// Page contact


for (let contactbuttons of contactbutton) {

contactbuttons.addEventListener('click', function () {
  pagecontent.classList.toggle('open');

});
}

croix.addEventListener('click', function () {
  pagecontent.classList.toggle('open');
});










